// @flow
import React, { type Node } from 'react';
import GlossaryLink from '../components/glossary-link';

type RatingType = {
  rating:
    | '1'
    | '2'
    | '3'
    | '4'
    | 'A'
    | 'B'
    | 'C'
    | 'NoTime'
    | 'I'
    | 'II'
    | 'III'
    | 'IV'
    | 'V'
    | 'VI'
    | 'NoRisk'
    | 'R'
    | 'X'
    | 'XX'
    | 'NoSlot'
    | 'S',
  ratingType: 'technical' | 'water' | 'time' | 'risk' | 'slot',
  description: Node,
};

export const ratingsArray: Array<RatingType> = [
  {
    rating: '1',
    ratingType: 'technical',
    description: 'A canyon that is non-technical and easy.',
  },
  {
    rating: '2',
    ratingType: 'technical',
    description: (
      <>
        Non-technical with lots of <GlossaryLink>down climbing</GlossaryLink>.{' '}
        <GlossaryLink>Hand lines</GlossaryLink> come in handy but no{' '}
        <GlossaryLink id="rappel">rappelling</GlossaryLink> required.
      </>
    ),
  },
  {
    rating: '3',
    ratingType: 'technical',
    description: 'Technical. Rappelling almost always required.',
  },
  {
    rating: '4',
    ratingType: 'technical',
    description: (
      <>
        Advanced. It is generally associated with rappels longer than 200′,
        canyons with <GlossaryLink>potholes</GlossaryLink>, or canyons that
        require creative anchor strategies such as{' '}
        <GlossaryLink>marginal anchors</GlossaryLink> or{' '}
        <GlossaryLink id="retrievable-rigging-system">
          retrievable rigging
        </GlossaryLink>
        .
      </>
    ),
  },
  {
    rating: 'A',
    ratingType: 'water',
    description:
      'Water levels are anything from completely dry to waist deep water. No swimming is required.',
  },
  {
    rating: 'B',
    ratingType: 'water',
    description: 'Deep wading to swimming. Water has no current.',
  },
  { rating: 'C', ratingType: 'water', description: 'Flowing water.' },
  {
    rating: 'NoTime',
    ratingType: 'time',
    description: '',
  },
  {
    rating: 'I',
    ratingType: 'time',
    description: 'It should take a few hours to complete the canyon.',
  },
  {
    rating: 'II',
    ratingType: 'time',
    description: 'It should take about half a day to complete the canyon.',
  },
  {
    rating: 'III',
    ratingType: 'time',
    description: 'It should take most of a day to complete the canyon.',
  },
  {
    rating: 'IV',
    ratingType: 'time',
    description: 'It should be a very long day to complete the canyon.',
  },
  {
    rating: 'V',
    ratingType: 'time',
    description: 'It should take about two days to complete the canyon.',
  },
  {
    rating: 'VI',
    ratingType: 'time',
    description: 'It should take three or more days to complete the canyon.',
  },
  {
    rating: 'NoRisk',
    ratingType: 'risk',
    description: '',
  },
  {
    rating: 'R',
    ratingType: 'risk',
    description: (
      <>
        A canyon with <GlossaryLink>exposure</GlossaryLink> where failure will
        likely result in serious injury.
      </>
    ),
  },
  {
    rating: 'X',
    ratingType: 'risk',
    description: (
      <>
        A canyon with <GlossaryLink>exposure</GlossaryLink> where failure will
        likely result in death.
      </>
    ),
  },
  {
    rating: 'XX',
    ratingType: 'risk',
    description: (
      <>
        A canyon with especially difficult,{' '}
        <GlossaryLink id="exposure">exposed</GlossaryLink> moves where failure
        will likely result in death.
      </>
    ),
  },
  {
    rating: 'NoSlot',
    ratingType: 'slot',
    description: '',
  },
  {
    rating: 'S',
    ratingType: 'slot',
    description: (
      <>
        The canyon is skinny enough that some people may not be able to fit
        through.
      </>
    ),
  },
];

const generateArray = (ratingType: string): Array<RatingType> => {
  return ratingsArray.filter((rating) => rating.ratingType === ratingType);
};

const generateMap = (ratingArray) =>
  ratingArray.reduce((acc, rating) => {
    acc[rating.rating] = rating;
    return acc;
  }, {});

export const ratingsMap = generateMap(ratingsArray);

export const technicalArray = generateArray('technical');
export const technicalMap = generateMap(technicalArray);

export const waterArray = generateArray('water');
export const waterMap = generateMap(waterArray);

export const timeArray = generateArray('time');
export const timeMap = generateMap(timeArray);

export const riskArray = generateArray('risk');
export const riskMap = generateMap(riskArray);

export const slotArray = generateArray('slot');
export const slotMap = generateMap(slotArray);

export default {
  technical: technicalMap,
  water: waterMap,
  time: timeMap,
  risk: riskMap,
  slot: slotMap,
};
