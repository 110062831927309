// @flow
import React from 'react';
import styled from 'styled-components';
import { color_border } from '../../utils/styles';

type StatusType = 'unselected' | 'correct' | 'incorrect';

const Button = styled(({ status, ...props }: { status: StatusType }) => (
  <button {...props} />
))`
  border: none;
  border-left: 4px solid
    ${(props: any) => {
      switch (props.status) {
        case 'correct':
          return 'green';
        case 'incorrect':
          return 'red';
        default:
          return color_border;
      }
    }};
  background-color: ${(props: any) => {
    switch (props.status) {
      case 'correct':
        return 'honeydew';
      case 'incorrect':
        return 'lightpink';
      default:
        return 'transparent';
    }
  }};
  transition: border-left-color 100ms, background-color 100ms;
  text-align: start;
  padding: 6px;
  margin: 2px;

  &:hover,
  &:active {
    text-decoration: none;
  }
`;

type Props = {
  text: string,
  index: number,
  onClick?: (index: number) => void,
  status: StatusType,
};

export default (props: Props) => {
  const handleClick = () => props.onClick && props.onClick(props.index);

  return (
    <Button onClick={handleClick} status={props.status}>
      {props.text}
    </Button>
  );
};
