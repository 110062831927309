// @flow
import React from 'react';
import Layout from '../components/layout';
import { withFrontMatter } from '../components/front-matter';
import type { FrontMatter } from '../utils/types';
import * as quizzes from '../data/quiz';
import Quiz from '../components/quiz';

class Canyoneering_Quizzes extends React.Component<{
  frontMatter: FrontMatter,
}> {
  render() {
    const { frontMatter } = this.props;

    Object.values(quizzes).forEach((quiz: any) => {
      delete quiz.maxQuestionCount;
    });

    return (
      <Layout frontMatter={frontMatter}>
        <p>All quizzes at their full length. Good luck!</p>
        {Object.values(quizzes).map((orig: any) => {
          const quiz = { ...orig };
          delete quiz.maxQuestionCount;
          return (
            <React.Fragment key={quiz.name}>
              <h2>{`${quiz.name} Quiz`}</h2>
              <Quiz quiz={quiz} />
            </React.Fragment>
          );
        })}
      </Layout>
    );
  }
}

export default withFrontMatter('quizzes')(Canyoneering_Quizzes);
