// @flow
import styled from 'styled-components';
import { border } from '../../utils/styles';

export default styled.button`
  align-self: center;
  text-align: center;
  background-color: transparent;
  ${border};
  padding: 0.33333rem 1.5rem;
  margin: 16px 0;
  transition: background-color 0.3s ease-in;

  &:disabled {
    color: rgba(0, 0, 0, 0.2);
  }

  &:hover,
  &:active {
    color: unset;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
