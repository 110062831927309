// @flow
import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import ccAxios from '../../utils/ccAxios';
import Collapsible from '../collapsible';
import type { QuizType, RawQuizType } from '../../utils/quiz';
import { generateQuiz } from '../../utils/quiz';
import Question from './question';
import ProgressBar from './progress-bar';
import IntroPage from './intro-page';
import FinalPage from './final-page';

const Container = styled.div`
  margin: 12px;
`;

type Props = {
  quiz: RawQuizType,
};

export default (props: Props) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(-1);
  const [correctCount, setCorrectCount] = useState<number>(0);
  const [correctRates, setCorrectRates] = useState<
    Array<{ id: string, correctRate: ?number }>
  >([]);
  const quiz: QuizType = useMemo(() => {
    return generateQuiz(props.quiz);
  }, [props.quiz]);

  /* eslint-disable */
  useEffect(() => {
    ccAxios
      .get('/quiz/' + props.quiz.id)
      .then((response) => {
        setCorrectRates(response.data);
      })
      .catch((_) => {});
  }, []);

  const page: 'intro' | 'question' | 'final' =
    currentQuestionIndex < 0
      ? 'intro'
      : currentQuestionIndex === quiz.questions.length
      ? 'final'
      : 'question';

  const handleNext = (correct: boolean) => {
    if (correct) {
      setCorrectCount(correctCount + 1);
    }
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const onRetry = () => {
    setCurrentQuestionIndex(-1);
    setCorrectCount(0);
  };

  const submitAnswer = (questionId: string, isCorrect: boolean) => {
    ccAxios.post(
      `/quiz/${quiz.id}/question/${questionId}/correct/${isCorrect.toString()}`
    );
  };

  let correctRate = null;
  if (quiz.questions[currentQuestionIndex]) {
    const id = quiz.questions[currentQuestionIndex].id;
    correctRate = (
      correctRates.find((rate) => {
        return rate.id === id;
      }) || { correctRate: null }
    ).correctRate;
  }

  return (
    <Collapsible hint={`Take the ${quiz.name} quiz`} visible={true}>
      <Container>
        {page === 'intro' && <IntroPage quiz={quiz} onBegin={handleNext} />}
        {page === 'question' && (
          <>
            <ProgressBar
              current={currentQuestionIndex + 1}
              max={quiz.questions.length}
            />
            <Question
              correctRate={correctRate}
              question={quiz.questions[currentQuestionIndex]}
              onFinishQuestion={handleNext}
              submitAnswer={submitAnswer}
            />
          </>
        )}
        {page === 'final' && (
          <FinalPage
            correctCount={correctCount}
            questionCount={quiz.questions.length}
            onRetry={onRetry}
          />
        )}
      </Container>
    </Collapsible>
  );
};
