// @flow
import React from 'react';
import styled from 'styled-components';
import BeginButton from './next-button';
import type { QuizType } from '../../utils/quiz';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TakeTheQuiz = styled.h2`
  margin-top: 0;
`;

type Props = {
  quiz: QuizType,
  onBegin: (false) => void,
};

export default ({ quiz, onBegin }: Props) => {
  const handleBegin = () => {
    onBegin(false);
  };

  return (
    <Container>
      <TakeTheQuiz>
        Take the <strong>{quiz.name}</strong> quiz
      </TakeTheQuiz>
      <p>
        {quiz.questions.length} questions
        {quiz.questions.length !== quiz.questionPoolCount
          ? ` out of a pool of ${quiz.questionPoolCount} questions`
          : ''}
      </p>
      <p>
        I track questions that are answered and answered correctly for a purely
        non-scientific and easy-to-cheat system. Enjoy!
      </p>
      <BeginButton onClick={handleBegin}>Begin</BeginButton>
    </Container>
  );
};
