// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import QuestionPage from './question-page';
import AnswerPage from './answer-page';
import type { QuestionType } from '../../utils/quiz';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.h3`
  margin-top: 1.66667rem;
`;

type CurrentPageType = 'question' | 'answer';

type Props = {
  correctRate: ?number,
  question: QuestionType,
  onFinishQuestion: (boolean) => void,
  submitAnswer: (questionId: string, isCorrect: boolean) => void,
};

export default ({
  correctRate,
  question,
  onFinishQuestion,
  submitAnswer,
}: Props) => {
  const [currentPage, setCurrentPage] = useState<CurrentPageType>('question');
  const [userAnswer, setUserAnswer] = useState<number>(0);

  const handleAnswerClick = (answer: number) => {
    submitAnswer(question.id, question.correctAnswer === answer);
    setUserAnswer(answer);
    setCurrentPage('answer');
  };

  const handleFinishQuestion = () => {
    setCurrentPage('question');
    onFinishQuestion(question.correctAnswer === userAnswer);
  };

  return (
    <Container>
      <Text>{question.text}</Text>
      {currentPage === 'question' ? (
        <QuestionPage
          answers={question.answers}
          onAnswerClick={handleAnswerClick}
        />
      ) : (
        <AnswerPage
          remarks={question.postAnswerRemarks}
          answers={question.answers}
          userAnswer={userAnswer}
          correctAnswer={question.correctAnswer}
          correctRate={correctRate}
          onNextClick={handleFinishQuestion}
        />
      )}
    </Container>
  );
};
