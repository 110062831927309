// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import Answer from './answer-button';
import NextButton from './next-button';

const Remarks = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

type Props = {
  remarks: Node,
  answers: Array<string>,
  userAnswer: number,
  correctAnswer: number,
  correctRate: ?number,
  onNextClick: () => void,
};

export default (props: Props) => {
  const correctRateAsPercentString =
    typeof props.correctRate === 'number'
      ? `${(props.correctRate * 100).toFixed(0)}%`
      : '';
  return (
    <>
      {props.answers.map((answer, index) => {
        return index === props.userAnswer || index === props.correctAnswer ? (
          <Answer
            key={index}
            index={index}
            status={index === props.correctAnswer ? 'correct' : 'incorrect'}
            text={answer}
          />
        ) : null;
      })}
      {props.remarks && <Remarks>{props.remarks}</Remarks>}
      {correctRateAsPercentString && (
        <>
          <hr />
          <span>
            This question is answered correctly about{' '}
            {correctRateAsPercentString} of the time
          </span>
        </>
      )}
      <NextButton onClick={props.onNextClick}>Next</NextButton>
    </>
  );
};
