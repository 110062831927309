// @flow
import type { Id, ImageType } from './types';
import { type Node } from 'react';
import { randomNum } from './random';

//region Generate Question

export type RawQuestionType = {
  // The unique identifier of the question
  id: Id,
  // Image to show with the question
  image?: ImageType,
  // If true, randomizes the answer order
  randomizeAnswers?: boolean,
  // If true, adds "All of the above" answer to the question
  allOfTheAbove?: boolean,
  // If true, adds "None of the above" answer to the question
  noneOfTheAbove?: boolean,
  // Question text - required
  text: Node,
  // Array of answers as strings or empty for True/False - required
  // If True/False, it will ignore randomizeAnswers, allOfTheAbove, and noneOfTheAbove
  answers: Array<string>,
  // The index of the correct answer where the first answer is 0.
  // If "All of the above" is true and the correct answer, this should be set as answers.length
  // If "None of the above is true and the correct answer, this should be set as answers.length + 1 if "All of the above" is true, otherwise it should be answers.length
  // If True/False, 0 is true and 1 is false,
  correctAnswer: number,
  // Remarks that are displayed after the question has been answered
  postAnswerRemarks: Node,
};

export type QuestionType = {
  // The unique identifier of the question
  id: string,
  // Image to show with the question
  image?: ImageType,
  // Question text - required
  text: Node,
  // Array of answers
  answers: Array<string>,
  // The index of the correct answer
  correctAnswer: number,
  // Remarks that are displayed after the question has been answered
  postAnswerRemarks: Node,
};

export const generateQuestion = (question: RawQuestionType): QuestionType => {
  let correctAnswer = question.correctAnswer;
  const answers: Array<string> = [...question.answers];
  if (answers.length === 0) {
    answers.push('True');
    answers.push('False');
  } else {
    if (question.randomizeAnswers) {
      const len = answers.length;
      for (let i = 0; i < len; i++) {
        // Swap answers i and j
        const j = randomNum(len);
        const temp = answers[i];
        answers[i] = answers[j];
        answers[j] = temp;

        // If the correct answer is either i or j, swap it
        if (correctAnswer === i) {
          correctAnswer = j;
        } else if (correctAnswer === j) {
          correctAnswer = i;
        }
      }
    }

    if (question.allOfTheAbove) {
      answers.push('All of the above');
    }
    if (question.noneOfTheAbove) {
      answers.push('None of the above');
    }
  }

  return {
    id: question.id,
    image: question.image,
    text: question.text,
    answers,
    correctAnswer,
    postAnswerRemarks: question.postAnswerRemarks,
  };
};

//endregion Generate Question

//region Generate Quiz

export type RawQuizType = {
  // id passed to platform code
  id: string,
  // The name of the quiz: `Take the ${quiz.name} quiz`
  // Also for the string when you are done with the quiz: `Generate another ${quiz.name} Quiz`
  name: string,
  // If true, randomizes the question order
  randomizeQuestionOrder?: boolean,
  // If there are more questions than maxQuestionCount, only have maxQuestionCount questions. Use with randomizeQuestionOrder: true
  maxQuestionCount?: number,
  // Array of questions to ask
  questions: Array<RawQuestionType>,
};

export type QuizType = {
  // id passed to platform code
  id: string,
  // The name of the quiz: `Take the ${quiz.name} quiz`
  // Also for the string when you are done with the quiz: `Generate another ${quiz.name} Quiz`
  name: string,
  // The total number of questions that could be asked
  questionPoolCount: number,
  // The questions to ask
  questions: Array<QuestionType>,
};

const randomizeQuestionOrder = (
  questions: Array<RawQuestionType>
): Array<RawQuestionType> => {
  const max = questions.length;
  for (let i = 0; i < max; i++) {
    const j = randomNum(max);
    const temp = questions[i];
    questions[i] = questions[j];
    questions[j] = temp;
  }
  return questions;
};

export const generateQuiz = (quiz: RawQuizType): QuizType => {
  const questionPoolCount = quiz.questions.length;

  const max = quiz.maxQuestionCount
    ? Math.min(quiz.maxQuestionCount, questionPoolCount)
    : questionPoolCount;

  const allQuestions: Array<RawQuestionType> = [...quiz.questions];
  const rawQuestions = quiz.randomizeQuestionOrder
    ? randomizeQuestionOrder(allQuestions)
    : allQuestions;
  const questions = rawQuestions.slice(0, max).map(generateQuestion);

  return {
    id: quiz.id,
    name: quiz.name,
    questionPoolCount,
    questions,
  };
};

//endregion Generate Quiz
