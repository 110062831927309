// @flow
import React from 'react';
import styled from 'styled-components';
import RetryButton from './next-button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScoreTitle = styled.h3`
  margin-top: 0;
`;

const Score = styled.h1`
  margin-top: 0;
  margin-bottom: 0.833333rem;
  text-align: center;
`;

const SubScore = styled.p`
  text-align: center;
`;

type Props = {
  correctCount: number,
  questionCount: number,
  onRetry: () => void,
};

export default (props: Props) => {
  return (
    <Container>
      <ScoreTitle>Your score:</ScoreTitle>
      <Score>
        {((props.correctCount * 100) / props.questionCount).toFixed(0)}%
      </Score>
      <SubScore>
        ({props.correctCount} / {props.questionCount})
      </SubScore>
      <RetryButton onClick={props.onRetry}>Restart</RetryButton>
    </Container>
  );
};
