// @flow
import React from 'react';
import styled from 'styled-components';
import { color_primary, large_screen } from '../../utils/styles';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Bar = styled(({ current, max, ...props }) => <div {...props} />)`
  width: ${(props: any) => (props.current / props.max) * 100 + '%'};
  height: 3px;
  background-color: ${color_primary};
  transition: width 1s;
`;

const Text = styled(({ current, max, ...props }) => <span {...props} />)`
  min-width: ${(props: any) => (props.current / props.max) * 100 + '%'};
  text-align: end;
  align-self: flex-start;
  color: ${color_primary};
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
  transition: min-width 1s;

  ${large_screen} {
    font-size: revert;
  }
`;

type Props = {
  current: number,
  max: number,
};

export default (props: Props) => {
  return (
    <Container>
      <Bar {...props} />
      <Text {...props}>
        {props.current} / {props.max}
      </Text>
    </Container>
  );
};
