// @flow
import React from 'react';
import Answer from './answer-button';

type Props = {
  answers: Array<string>,
  onAnswerClick: (number) => void,
};

export default (props: Props) => {
  return (
    <>
      {props.answers.map((text, index) => (
        <Answer
          key={index}
          text={text}
          index={index}
          onClick={props.onAnswerClick}
          status={'unselected'}
        />
      ))}
    </>
  );
};
